import request from '../request.js'

// 获取 主体列表
const getLimits = function (data) {
    return request({
        url: 'sysUserRoleTenantLimit/getLimits',
        method: 'post',
        data
    })
}

// 以 租户 形式登录
const getTenants = function (data) {
    return request({
        url: 'sysTenant/getTenants',
        method: 'post',
        data
    })
}

// 以 租户和主体 形式登录
const getTenantAndLimits = function (data) {
    return request({
        url: 'sysUserRoleTenantLimit/getTenantAndLimits',
        method: 'post',
        data
    })
}


/**
 * 获取ak
 */
const selectValueByKey = function (data) {
    return request({
        url: 'sysParameter/selectValueByKey',
        method: 'post',
        data,
    })
}

export { getLimits, getTenants, getTenantAndLimits, selectValueByKey }