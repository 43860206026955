<template>
    <div class="choseCompany-main">
        <div class="main-box">
            <div class="box"
                 v-for="(i, index) in mainData"
                 :key="'mainData' + index" 
                 @click="jump(i)">{{i.sname}}</div>
        </div>
    </div>
</template>

<script>
import { setItem } from '@/utils/storage'
import { getTenants } from 'api/system/common'

export default {
    name: 'choseCompany',
    data() {
        return {
            mainData: []
        }
    },
    methods: {
        jump(data) {
            setItem('tenantId', data.id)
            this.$router.push({ path: '/main' })
        },

        getTenants() {
            getTenants().then(res => {
                console.log(res, 'choseCompany')
                this.mainData = res.data
            })
        }
    },
    created() {
        if (typeof this.$route.params.from !== 'undefined') {
            this.mainData = this.$route.params.data.tenantList
        } else {
            this.getTenants()
        }
    }
} 
</script>

<style lang="less">
.choseCompany-main {
    .main-box {
        margin-top: 100px;
        display: flex;
        justify-content: space-around;
        .box {
            width: 400px;
            height: 400px;
            line-height: 400px;
            text-align: center;
            cursor: pointer;
            border: 2px solid black;
            box-sizing: border-box;
        }
    }
}
</style>


